<template>
  <div>
    <Header />
    <ConsultaClientes />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderGeral.vue"
import ConsultaClientes from "../components/ConsultaClientes.vue"
import Footer from "../components/Footer.vue"

export default {
  components: {
    Header,
    ConsultaClientes,
    Footer
  }
}

</script>
