<template>
  <div class="mt-2 mt-16 pb-16">
    <v-row justify="center">
      <v-col class="align-center">
        <v-data-table
          dense
          :headers="headers"
          :items="clientes"
          item-key="id"
          height="auto"
          locale="pt-BR"
          class="table-mb elevation-10 mt-10 ml-10 mr-10 mb-10"
          :search="search"
        >
          <template v-slot:item.administrador="{ item }">
            <v-checkbox
              dense
              readonly
              v-model="item.administrador"
            ></v-checkbox>
          </template>

          <template v-slot:top>
            <v-toolbar id="tb" flat>
              <v-toolbar-title>Cadastro de Clientes</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-icon class="mt-2">mdi-magnify</v-icon>
              <v-text-field
                v-model="search"
                label="Pesquisar"
                class="ml-1 mt-7 mr-7"
              ></v-text-field>
              <v-btn
                color="black"
                dark
                class="mb-2"
                @click="newItem()"
              >
                Novo Cliente
              </v-btn>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Tem certeza que deseja excluir o cliente
                    {{editedItem.nome}}<br>
                    {{editedItem.empresa}}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                      Excluir
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDuplicate" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Deseja duplicar o cadastro do cliente<br>
                    {{editedItem.nome}}<br>
                    {{editedItem.empresa}}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDuplicate">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="duplicateItemConfirm">
                      Duplicar
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogLink" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Dados para o cartão</v-card-title
                  >
                  <v-card-text
                    >Link NFC:
                    <a :href="linkconsulta" target="blank">{{
                      linkconsulta
                    }}</a></v-card-text
                  >
                  <v-card-text>
                    <img :src="qrcode" />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeLink"
                      >Fechar</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            Ainda não existem dados cadastrados
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
            <v-icon small  class="mr-2" @click="duplicateItem(item)">
              mdi-content-duplicate
            </v-icon>
            <v-icon small @click="apresentarLink(item)">
              mdi-qrcode 
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import svcClientes from "../services/svcClientes";
import { decriptar, encriptar } from "../services/cripto";

export default {
  data() {
    return {
      passwordVisible: false,
      search: "",
      clientes: [],
      headers: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Email", value: "email" },
        { text: "Empresa", value: "empresa" },
        { text: "Tel. Fixo", value: "telefonefixo1" },
        { text: "Tel. Celular", value: "telefonecelular1" },
        { text: "Adm", value: "administrador", type: "checkbox" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      dialogDuplicate: false,
      dialogLink: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        nome: "",
        apelido: "",
        empresa: "",
        cargo: "",
        endereco1: "",
        endereco2: "",
        endereco3: "",
        pix: "",
        pixcopiaecola: "",
        telefonefixo1: "",
        telefonefixo2: "",
        telefonefixo3: "",
        telefonenacional: "",
        telefonecelular1: "",
        telefonecelular2: "",
        telefonecelular3: "",
        email: "",
        whatsapp1: "",
        whatsapp2: "",
        whatsapp3: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        youtube: "",
        google1: "",
        google2: "",
        google3: "",
        avaliacao: "",
        cardapio: "",
        ifood: "",
        website: "",
        olx: "",
        appapple: "",
        appandroid: "",
        texto: "",
        senha: "",
        administrador: false,
        imagem: "",
        estiloimagem: "",
        codigoconsulta: "",
        tipofundo: "",
        corfundo: "",
        corfonte: "",
      },
      defaultItem: {
        id: "",
        nome: "",
        apelido: "",
        empresa: "",
        cargo: "",
        endereco1: "",
        endereco2: "",
        endereco3: "",
        pix: "",
        pixcopiaecola: "",
        telefonefixo1: "",
        telefonefixo2: "",
        telefonefixo3: "",
        telefonenacional: "",
        telefonecelular1: "",
        telefonecelular2: "",
        telefonecelular3: "",
        email: "",
        whatsapp1: "",
        whatsapp2: "",
        whatsapp3: "",
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        youtube: "",
        google1: "",
        google2: "",
        google3: "",
        avaliacao: "",
        cardapio: "",
        ifood: "",
        website: "",
        olx: "",
        appapple: "",
        appandroid: "",
        texto: "",
        senha: "",
        administrador: false,
        imagem: "",
        estiloimagem: "",
        codigoconsulta: "",
        tipofundo: "",
        corfundo: "",
        corfonte: "",
      },
      linkconsulta: "",
      imagem: null,
      imagembase64: "",
      isSelecting: false
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Cliente" : "Editar Cliente";
    },

    qrcode() {
      var QRCode = require("qrcode");
      var base64 = "";
      QRCode.toDataURL(this.linkconsulta, function (err, code) {
        if (err) {
          console.log(err);
        } else {
          base64 = code;
        }
      });
      return base64;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDuplicate(val) {
      val || this.closeDuplicate();
    },
    dialogLink(val) {
      val || this.closeLink();
    },
  },

  methods: {
    passwordVisibleToggle() {
      this.passwordVisible = !this.passwordVisible;
    },
    lista() {
      svcClientes
        .listar()
        .then((resp) => {
          this.clientes = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    newItem() {
     this.$router.push("/new");
    },

    editItem(item) {
      // this.editedIndex = this.clientes.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.editedItem.senha = decriptar(this.editedItem.senha);
      // this.imagembase64 = process.env.VUE_APP_URL_IMAGES + "/" + this.editedItem.imagem
      // this.dialog = true;
     this.$router.push("/edit/" + item.codigoconsulta);
    },

    apresentarLink(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.linkconsulta =
        window.location.protocol +
        "//" +
        window.location.host +
        "/#/view/" +
        this.editedItem.codigoconsulta;

      this.dialogLink = true;
    },

    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      svcClientes
        .excluir(this.editedItem.id)
        .then((resp) => {
          if (resp.status == 200) {
            this.lista();
          } else {
            alert("Erro excluindo um cliente.");
          }
        })
        .catch((error) => {
          alert(error);
        });
      this.closeDelete();
    },

    duplicateItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDuplicate = true;
    },

    duplicateItemConfirm() {
      console.log(this.editedItem.codigoconsulta)
      svcClientes
        .incluir(this.editedItem)
        .then((resp) => {
          if (resp.status == 200) {
            this.editedItem = resp.data;
            this.editedItem.codigoconsulta = encriptar(this.editedItem.id);
            svcClientes.alterar(this.editedItem)
            .then((resp) => {
              this.lista();
            })
          } else {
            alert("Erro duplicando um cliente.");
          }
        })
        .catch((error) => {
          alert(error);
        });
      this.closeDuplicate();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      imagem = null
      imagembase64 = ""
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDuplicate() {
      this.dialogDuplicate = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeLink() {
      this.dialogLink = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      svcClientes
        .uploadimage(this.editedItem.codigoconsulta, this.imagem)
        .then((resp) => {
          if (resp.status == 200) {
            this.editedItem.imagem = resp.data.imagem
            this.confirmaDados()
            this.close();
          } else {
            alert("Erro no upload da imagem. Alteração cancelada.");
          }
        })
        .catch((error) => {
          alert(error);
        });

      this.confirmaDados()
    },

    confirmaDados() {
      if (this.editedIndex > -1) {
        // Editar Cliente
        this.editedItem.senha = encriptar(this.editedItem.senha);
        svcClientes
          .alterar(this.editedItem)
          .then((resp) => {
            if (resp.status == 200) {
              this.lista()
              //              Object.assign(this.clientes[this.editedIndex], this.editedItem)
            } else {
              alert("Erro alterando dados do cliente.");
            }
          })
          .catch((error) => {
            alert(error)
          });
      } else {
        // Novo Cliente
        this.editedItem.senha = encriptar(this.editedItem.senha);
        svcClientes
          .incluir(this.editedItem)
          .then((resp) => {
            if (resp.status == 200) {
              console.log(resp.data.id)
              this.editedItem = resp.data
              this.editedItem.codigoconsulta = encriptar(this.editedItem.id)
              console.log(this.editedItem.id)
              svcClientes.alterar(this.editedItem)
              this.lista()
              // this.clientes.push(this.editedItem)
              // this.clientes.id = resp.data.id
            } else {
              alert("Erro inserindo novo cliente.");
            }
          })
          .catch((error) => {
            alert(error);
          });
      }
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        }, { once: true }
      );

      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      this.imagem = e.target.files[0];

      var reader = new FileReader();

      const callback = () => {
          this.imagembase64 = reader.result
      }
      reader.onload = callback
      reader.readAsDataURL(this.imagem);
    }
  },

  mounted() {
    if (localStorage.getItem("ma") == null) {
      this.$router.push("/");
    }
    if (decriptar(localStorage.getItem("ad")) == "false") {
      this.$router.push("/edit/" + localStorage.getItem("di"));
    }

    this.lista();
  },
};
</script>

<style>
/* tbody tr:nth-of-type(even) {
    background-color: linen;
  }

  tbody tr:nth-of-type(odd) {
    background-color: white;
  } */

tbody tr {
  background-color: #e7c5ae;
  color: dimgrey;
}

.v-data-footer {
  background-color: #e7c5ae;
  font-weight: bolder;
}

.v-data-table thead tr th {
  background-color: #e7c5ae;
  color: white;
  font-weight: 900;
}

#tb div {
  background-color: #e7c5ae;
}
</style>


              // <v-dialog v-model="dialog" max-width="1000px">
              //   <template v-slot:activator="{ on, attrs }">
              //     <v-btn
              //       color="black"
              //       dark
              //       class="mb-2"
              //       @click="newItem()"
              //     >
              //       Novo Cliente
              //     </v-btn>
              //   </template>
              //   <v-card>
              //     <v-card-title>
              //       <span class="text-h5">{{ formTitle }}</span>
              //     </v-card-title>

              //     <v-card-text>
              //       <v-container>
              //         <v-row>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.nome"
              //               label="Nome"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.apelido"
              //               label="Apelido"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.empresa"
              //               label="Empresa"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.cargo"
              //               label="Cargo"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="12">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.endereco1"
              //               label="Endereço 1"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="12">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.endereco2"
              //               label="Endereço 2"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="12">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.endereco3"
              //               label="Endereço 3"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.telefonefixo1"
              //               label="Tel. Fixo 1"
              //               v-mask="'(##) ####-####'"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.telefonefixo2"
              //               label="Tel. Fixo 2"
              //               v-mask="'(##) ####-####'"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.telefonefixo3"
              //               label="Tel. Fixo 3"
              //               v-mask="'(##) ####-####'"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.telefonecelular1"
              //               label="Tel. Celular 1"
              //               v-mask="'(##) #####-####'"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.telefonecelular2"
              //               label="Tel. Celular 2"
              //               v-mask="'(##) #####-####'"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.telefonecelular3"
              //               label="Tel. Celular 3"
              //               v-mask="'(##) #####-####'"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.email"
              //               label="Email"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               hide-details="auto"
              //               v-model="editedItem.whatsapp1"
              //               label="Whatsapp1"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               prefix="https://www.facebook.com/"
              //               hide-details="auto"
              //               v-model="editedItem.facebook"
              //               label="Facebook"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               prefix="https://twitter.com/"
              //               hide-details="auto"
              //               v-model="editedItem.twitter"
              //               label="Twitter"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               prefix="https://www.instagram.com/"
              //               hide-details="auto"
              //               v-model="editedItem.instagram"
              //               label="Instagram"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               prefix="https://www.linkedin.com/in/"
              //               hide-details="auto"
              //               v-model="editedItem.linkedin"
              //               label="Linkedin"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               prefix="https://www.youtube.com/"
              //               v-model="editedItem.youtube"
              //               label="Youtube"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-text-field
              //               dense
              //               v-model="editedItem.website"
              //               label="Website"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="6">
              //             <v-textarea
              //               dense
              //               height="100"
              //               v-model="editedItem.texto"
              //               label="Texto Livre"
              //             ></v-textarea>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-img id="imagem"
              //               :src="imagembase64"
              //               alt="Sem imagem"
              //               width="100"
              //             />
              //           </v-col>
              //           <v-col cols="2">
              //             <v-btn
              //               color="primary"
              //               class="text-none"
              //               rounded
              //               depressed
              //               :loading="isSelecting"
              //               @click="onButtonClick"
              //             >
              //               <v-icon left>mdi-cloud-upload</v-icon>
              //               Upload
              //             </v-btn>
              //             <input
              //               ref="uploader"
              //               class="d-none"
              //               type="file"
              //               accept="image/*"
              //               @change="onFileChanged"
              //             />
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               v-model="editedItem.pix"
              //               label="PIX"
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="4">
              //             <v-text-field
              //               v-model="editedItem.senha"
              //               label="Senha"
              //               name="password"
              //               @click:append="passwordVisibleToggle"
              //               :type="passwordVisible ? 'text' : 'password'"
              //               :append-icon="
              //                 passwordVisible ? 'mdi-eye-off' : 'mdi-eye'
              //               "
              //             ></v-text-field>
              //           </v-col>
              //           <v-col cols="1"></v-col>
              //           <v-col cols="3">
              //             <v-switch
              //               v-model="editedItem.administrador"
              //               inset
              //               :label="`${
              //                 editedItem.administrador
              //                   ? 'Usuário é administrador'
              //                   : 'Usuário não é administrador'
              //               }`"
              //             ></v-switch>
              //           </v-col>
              //         </v-row>
              //       </v-container>
              //     </v-card-text>

              //     <v-card-actions>
              //       <v-spacer></v-spacer>
              //       <v-btn color="blue darken-1" text @click="close">
              //         Cancelar
              //       </v-btn>
              //       <v-btn color="blue darken-1" text @click="save">
              //         Salvar
              //       </v-btn>
              //     </v-card-actions>
              //   </v-card>
              // </v-dialog>



